@font-face {
    font-family: 'WebNimbus Sans L';
    src: url('/css/font/subset-NimbusSanL-BolIta.eot');
    src: url('/css/font/subset-NimbusSanL-BolIta.eot?#iefix') format('embedded-opentype'),
        url('/css/font/subset-NimbusSanL-BolIta.woff2') format('woff2'),
        url('/css/font/subset-NimbusSanL-BolIta.woff') format('woff'),
        url('/css/font/subset-NimbusSanL-BolIta.ttf') format('truetype'),
        url('/css/font/subset-NimbusSanL-BolIta.svg#NimbusSanL-BolIta') format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'WebNimbus Sans L';
    src: url('/css/font/subset-NimbusSanL-Reg.eot');
    src: url('/css/font/subset-NimbusSanL-Reg.eot?#iefix') format('embedded-opentype'),
        url('/css/font/subset-NimbusSanL-Reg.woff2') format('woff2'),
        url('/css/font/subset-NimbusSanL-Reg.woff') format('woff'),
        url('/css/font/subset-NimbusSanL-Reg.ttf') format('truetype'),
        url('/css/font/subset-NimbusSanL-Reg.svg#NimbusSanL-Reg') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'WebNimbus Sans L';
    src: url('/css/font/subset-NimbusSanL-Bol.eot');
    src: url('/css/font/subset-NimbusSanL-Bol.eot?#iefix') format('embedded-opentype'),
        url('/css/font/subset-NimbusSanL-Bol.woff2') format('woff2'),
        url('/css/font/subset-NimbusSanL-Bol.woff') format('woff'),
        url('/css/font/subset-NimbusSanL-Bol.ttf') format('truetype'),
        url('/css/font/subset-NimbusSanL-Bol.svg#NimbusSanL-Bol') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'WebNimbus Sans L';
    src: url('/css/font/subset-NimbusSanL-RegIta.eot');
    src: url('/css/font/subset-NimbusSanL-RegIta.eot?#iefix') format('embedded-opentype'),
        url('/css/font/subset-NimbusSanL-RegIta.woff2') format('woff2'),
        url('/css/font/subset-NimbusSanL-RegIta.woff') format('woff'),
        url('/css/font/subset-NimbusSanL-RegIta.ttf') format('truetype'),
        url('/css/font/subset-NimbusSanL-RegIta.svg#NimbusSanL-RegIta') format('svg');
    font-weight: 500;
    font-style: italic;
}