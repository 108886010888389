.formsimple-selectmultiple{


	& .label {
		background-color: #ffffff;
	}
	& .component {


		& .botoes button{

			background-color: $gray5;
		}
	}
}