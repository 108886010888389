/*
	PAGINA INTERNA DO IMÓVEL
	-SESSÕES-	
*/
@import 'imoveis-ver/h1.scss';
@import 'imoveis-ver/acao-tipo-titulo.scss';
@import 'imoveis-ver/galeria.scss';
@import 'imoveis-ver/informacoes.scss';
@import 'imoveis-ver/promocao.scss';
@import 'imoveis-ver/mcmv.scss';
@import 'imoveis-ver/troca.scss';
@import 'imoveis-ver/infraestruturas.scss';
@import 'imoveis-ver/ckeditor.scss';
@import 'imoveis-ver/mapa.scss';
@import 'imoveis-ver/contato.scss';
@import 'imoveis-ver/favorito.scss';
@import 'imoveis-ver/nota.scss';
@import 'imoveis-ver/sharefavorinota.scss';

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin imovel--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin imovel--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin imovel--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin imovel--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin imovel--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin imovel--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin imovel--smart-old(){
}

.margin_0{
	margin: 0 !important;
}

.container-top{
	display: flex;
	justify-content: start;
	align-items: center;

	& > div {
		display: flex;
		justify-content: start;
		align-items: center;
		
		a{
			max-width: 250px;
			margin: 0;
		}
	}

	button{
		min-width: 180px;
		margin: 0;
		padding: 0;
	}

	

	p{
		min-width: 10ch;
	}
}

.flex-column-mobile{
	display: flex;
	gap: .5rem;
	margin-bottom: 1rem;

	& p {
		margin: 0;
	}
}

@media (max-width:500px){

	.container-top{
		display: flex;
		flex-direction: column;
		align-items: start;
		& > div {
			flex-direction: column;
			align-items: start;
		}
	}

	/* button:nth-child(1){
		margin: 0;
		text-align: center;
		padding: 0;
		margin-bottom: 1rem;
		font-size: 1rem;
	} */
	
}

@media (max-width:1023px){
	.container-top{
		justify-content: space-between;
	}

	.flex-column-mobile{
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		margin: 0 0 1.5rem 0;

		& div {
			margin: 0;
		}
	}
}