/* SELECT */
.boss-select{
	outline: none;
	vertical-align: middle;
	display: inline-block;
	position: relative;
	min-width: 50px;
	cursor: pointer !important;
	line-height: #{($formsimple_inputHeight - ($formsimple_inputBorder * 2))}px;
	@include margin-padrao;
	background-color: $formsimple_inputBackgroundColor;
	border: #{$formsimple_inputBorder}px solid $formsimple_inputBorderColor;
	border-radius: #{$formsimple_inputBorderRadius_a}rem;
	@include border-box;
	user-select: none;
	@include formsimple-font;
}
.boss-select select{
	padding: 0px #{$formsimple_inputHeight - ($formsimple_inputBorder * 2)}px 0px #{$formsimple_inputPdRight}px;
	cursor: pointer !important;
	width: 100%;
	line-height: inherit;
	background-color: rgba(0,0,0,0) !important;
	border: none !important;
	z-index: 1;
	font-size: inherit;
	font-weight: inherit;
	border-radius: #{$formsimple_inputBorderRadius_a};
}
.boss-select label{
	position: absolute;
	right: 0;
	bottom: 0;
	display: block;
	content: "";
	width: #{$inputHeight}px;
	z-index: 1;
}
.boss-select label::after{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	display: block;
	z-index: 0;
	text-align: center;
	width: #{$inputHeight}px;
	line-height: #{$inputHeight - ($inputBorder * 2)}px;
	height: #{$inputHeight - ($inputBorder * 2)}px;
	content: "\f078";
	font-size: 100% !important;
	color: $labelColor;
	font-family: 'Objetiva Software Light' !important;
	transform: rotate(0deg);
	transform-origin: center;
	transition: transform 0.15s ease;
}
.boss-select-disabled{
	cursor: default !important;
	border-color: $gray4 !important;
	color: $gray4 !important;
}
.boss-select-disabled select{
	cursor: default !important;
	color: $gray4 !important;
}
.boss-select-disabled label::before{
	cursor: default !important;
	background-color: $gray4 !important;
}
.boss-select-disabled label::after{
	cursor: default !important;
	color: #FFF !important;
}


.boss-js-select {
	position: relative;
	display: inline-block;
	vertical-align: top;
	outline: none;
	height: 35px;
	line-height: 36px;
	min-width: 135px;
	background-color: rgba(0,0,0,0);
	border-radius: #{$formsimple_inputBorderRadius_a};
	@include border-box;
}

.boss-js-select select {
	opacity: 0;
	position: absolute;
	z-index: 1;
	width: 1px;
	height: 1px;
	@include border-box;
}

.boss-js-select-label {
	cursor: pointer;
	height: inherit;
	line-height: inherit;
	padding: 0 5px;
	color: #3c3641;
	font-size: .9em;
	height: inherit;
	line-height: inherit;
	position: relative;
	z-index: 3;
	@include border-box;
}

.boss-js-select-label-show {
	left: -1px;
	right: -1px;
	display: block;
	background-color: #FFF;
	border: 1px solid #757575;
	position: absolute;
	z-index: 4;
	@include border-box;
}

.boss-js-select-search,
.boss-js-select-check-all {
	padding: 2px 5px;
	background-color: #FFF;
	height: inherit;
	line-height: inherit;
	@include border-box;
}

.boss-js-select-search-input {
	border: 1px solid #AAA;
	outline: none;
	height: 30px;
	width: 100%;
	padding-left: 5px;
	padding-right: 30px;
	@include border-box;
}

.boss-js-select-search div {
	@include user-select(none);
}

.boss-js-select-search span {
	cursor: pointer;
	top: 4px;
	right: 6px;
	width: 30px;
	height: 28px;
	display: block;
	text-align: center;
	position: absolute;
	color: #333;
	z-index: 2;
	color: #333;
	@include rotate(45deg);
	@include user-select(none);
	font: 500 20px/28px 'sans'
}

.boss-js-select div.button {
	cursor: pointer;
	float: right;
	vertical-align: middle;
	border: 1px solid #757575;
	background-color: #FFF;
	height: 26px;
	line-height: 24px;
	padding: 0 5px;
	margin-top: 3px;
	margin-left: 5px;
	min-width: 35px;
	font-size: 10px;
	font-weight: 600;
	border-radius: #{$formsimple_inputBorderRadius_a};
	text-align: center;
	text-transform: uppercase;
	@include border-box;
}

.boss-js-select div.button:hover {
	background-color: #757575;
	color: #3c3641
}

.boss-js-select-list {
	background-color: #fff;
	width: 100%;
	display: block;
	max-height: 320px;
	overflow: auto
}

.boss-js-select-list ul {
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%
}

.boss-js-select-list ul li {
	position: relative;
	list-style: none;
	padding: 2px 5px;
	@include border-box;
}


/* SELECT */
.boss-select{
	outline: none;
	cursor: pointer;
	display: inline-block;
	vertical-align: top;
	position: relative;
	z-index: 1;
	border: 1px solid #AAA;
	background-color: #FFF;
	line-height: 35px;
	height: 35px;
    min-width: 35px;
    margin: .15rem 0 .15rem 0;
    min-width: 35px;
    margin: .15rem 0 .15rem 0;
    padding: 0px 20px 0px 20px;
    background-color: #fff;
    border: 1px solid #343434;
	border-radius: #{$formsimple_inputBorderRadius_a};
}
.boss-select select{
	position: absolute;
	cursor: pointer;
	padding: 0px 5px;
	margin: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
    line-height: 35px;
    min-width: 35px;
    margin: .15rem 0 .15rem 0;
	z-index: 2;
	border: 2px solid rgba(0,0,0,0);
	background-color: rgba(0,0,0,0);
	width: 100%;
	border-radius: .9rem;
    margin: .15rem 0 .15rem 0;
    background-color: #fff;
    padding: 0px 20px 0px 20px;
    border: 1px solid #343434;
}
.boss-select::before{
	content: '';
	pointer-events: none;
	position: absolute;
	top: 45%;
	right: 20px;
	display: block;
	width: 0; 
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid #AAA;
	z-index: 999;
	-webkit-animation: all 0.15s ease-in-out;
	-moz-animation: all 0.15s ease-in-out;
	-o-animation: all 0.15s ease-in-out;
	-ms-animation: all 0.15s ease-in-out;
	animation: all 0.15s alternate;
	-webkit-transition: all 0.15s ease-in-out;
	-moz-transition: all 0.15s ease-in-out;
	-o-transition: all 0.15s ease-in-out;
	-ms-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
}
.boss-select:focus::before, .boss-select:active::before{
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
	-webkit-animation: all 0.15s ease-in-out;
	-moz-animation: all 0.15s ease-in-out;
	-o-animation: all 0.15s ease-in-out;
	-ms-animation: all 0.15s ease-in-out;
	animation: all 0.15s alternate;
	-webkit-transition: all 0.15s ease-in-out;
	-moz-transition: all 0.15s ease-in-out;
	-o-transition: all 0.15s ease-in-out;
	-ms-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
}