#boss-dialog, .boss-dialog{
	position: fixed;
	z-index: 998;
	top: 0px;
	left: 0px;
	background-color: rgba(0,0,0,0.7);
	width: 100vw;
	height: 100vh;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
}
.boss-dialog-area{
	position: relative;
	display: block;
	max-width: calc(100vw - 20px);
	max-height: calc(100vh - 80px);
	background-color: #ffffff;
	border: none;
	padding: 1rem;
	//min-width: 32rem;
	@include border-box;

}

.boss-dialog-img{
	display: block;
	max-width: calc(100vw - 40px);
	max-height: calc(100vh - 60px);
}
.boss-dialog-invisible{
	background-color: rgba(0,0,0,0) !important;
	border: none !important;
}
.boss-dialog-area iframe{
	border: none;
	position: relative;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
}
.boss-dialog-close{
	cursor: pointer;
	position: absolute;
	top: -10px;
	right: -10px;
	font-size: 18px;
	background-color: $colorPrimary;
	border: 2px solid $colorPrimary;
	color: $colorWhite;
	border-radius: 50%;
	width: 36px;
	line-height: 32px;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	z-index: 2;

	&:hover, &:active, &:focus{
		background-color: $colorWhite;
		border-color: $colorPrimary;
		color: $colorPrimary;
	}
}