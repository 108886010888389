.headerindex {
	height: calc(100vh + 1px);
	min-height: 40rem;
	top: -5.1rem;
	display: block;
	width: 0;
	padding: 0;
	@include border-box;
	margin-bottom: -5rem;
	z-index: 2;
}
.headerinterno {
	height: 5rem;
	z-index: 1;
}

.menu-central {
	background: $gray2;
	padding: .1rem .7rem;
	@include border-box;
	@include border-radius('.8rem');
}

.bg-condominioscolor{
	display: table;
	width: 100%;
	background-color: $condominiocolor;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}

.headerpc {
	display: block;
	width: 100%;
	position: relative;
	background-color: $colorTerciary;
	//background-image: url('/jnh/background/home.jpg');
	background-repeat: no-repeat;
	background-position: center;

	& .superior {
		display: block;
		width: 100%;
		color: #ffffff;
		z-index: 3;
		vertical-align: middle;
		
		& .logo {
			display: inline-block;
			margin-left: 0;
			width: 4rem;
			padding: .5rem;
			padding-left: 0;
			@include border-box;
			vertical-align: middle;

			& img {
				display: block;
				width: 100%;
			}
		}

		& .menu-mobile {
			display: inline-block;
			width: calc(100% - 4rem);
			vertical-align: middle;

			& .bcentral {
				position: absolute;
				left: 50%;
				transform: translate(-45%, 0);
				top: 1rem;
			}
			& .b-menu {
				position: absolute;
				top: 1rem;
				right: 1rem;
			}
		}

		& .creci {
			display: inline-block;
			width: 10rem;
			vertical-align: middle;
		}
		& .menu {
			text-align: right;
			display: inline-block;
			//width: 4rem;
			vertical-align: middle;
		}

		& .link {
			width: calc(100% - 23rem);
			text-align: right;

			display: inline-block;

			& a {
				padding: 0 1rem;
				@include border-box;
				vertical-align: middle;
			
				&:hover {
					color: $colorWhite;
				}
				& img {
					display: inline-block;
					width: 1.5rem;
					vertical-align: middle;
				}
			}

		}
	}

	& .busca {

		display: block;
		position: absolute;
		width: 50%;
		top: 50%;
		right: 50%;
    	width: 70%;
		transform: translate(50%, -50%);
		text-align: center;
		z-index: 2;

		& h2 {
			color: #ffffff;
			font-size: 2rem;
		}
	}
}







$logosize: 10;
$rightsize: 17;
$tamanhoHeaderSuperior: 1.5rem;

.header, .headerpc{
	display: block;
	width: 100%;

	& .top{
		display: block;
		background-color: $colorPrimary;
		width: 100%;
		height: $tamanhoHeaderSuperior;
		line-height: $tamanhoHeaderSuperior;
		color: #FFF;

		& .left, & .right{
			display: inline-block;
			vertical-align: middle;
			padding: 0;
			margin: 0;
			height: $tamanhoHeaderSuperior;
			line-height: $tamanhoHeaderSuperior;
		}

		& .left{

			width: calc(100% - #{$rightsize}rem);

			& .item{
				display: inline-block;
				vertical-align: middle;
				margin-right: 1rem;
				font-size: 0.9rem;
				font-weight: 400;
			}
		}

		& .right{

			width: #{$rightsize}rem;
			height: inherit;
			line-height: inherit;
			text-align: right;

			& a, & a:visited{
				color: #FFF;
			}

			& .social{
				position: relative;
				display: inline-block;
				vertical-align: middle;
				font-size: 1.1rem;
				width: 2.5rem;
				text-align: center;
				height: inherit;
				line-height: inherit;
				border-right: 1px solid rgba(255,255,255,0.2);

				& .favoritosqt{
					position: absolute;
					display: block;
					font-size: 0.5rem;
					top: 0.4rem;
					right: 1.85rem;
					font-weight: 600;
				}
			}

			& .central{
				display: inline-block;
				vertical-align: middle;
				height: inherit;
				line-height: inherit;
				font-weight: 600;
				font-size: 0.9rem;
				text-align: center;
				width: 6rem;
			}
		}
	}

	& .head{

		display: block;
		background: $colorTerciary;

		& .logo{
			display: inline-block;
			vertical-align: middle;
			width: #{$logosize}rem;
			//height: 9.5rem;
			line-height: 9.5rem;

			background-color: $colorTerciary;

			& img{
				display: block;
				//height: 6rem;
				//margin-top: 1.8rem;
			}
		}

		& .menus{
			display: inline-block;
			vertical-align: middle;
			width: calc(100% - #{$logosize}rem);
			color: #ffffff;
			padding-left: 3rem;
			@include border-box;

			& .area{
				vertical-align: middle;
				width: 100%;
				display: flex;
				justify-content: space-between;

				& .menu{

					& .a, & .button, .sub{
						display: block;
						height: 2.5rem;
						line-height: 2.5rem;
						font-weight: 500;
						color: #ffffff;
						font-size: 1rem;
						vertical-align: middle;
					}

					.sub{
						position: relative;
						text-align: left !important;

						& .subitem{
							display: block;
							font-size: 1rem;
							cursor: pointer;
							height: 2.5rem;
							line-height: 2.5rem;
							padding: 0 1rem;
							@include border-box;
						}

						& .subitens{
							display: block;
							text-align: left;
							position: absolute;
							display: none;
							top: 2.4rem;
							left: 0;
							min-width: 12rem;
							z-index: 9900;
							background-color: #FFF;
							overflow: auto;
							max-height: 70vh;

							& a, & a:visited{
								display: block;
								font-size: 0.9rem;
								font-weight: 400;
								padding: 0.20rem 1rem;
								text-align: left !important;
								width: 100%;
								@include border-box;
							}

							& a:hover{
								background-color: $colorPrimary;
								color: #FFF;

								& p {
									color: #FFF;
								}

							}
						}
					}

					.sub:hover{
						background-color: #FFF;
					}

					.sub:hover .subitens{
						display: block;
					}

					& .button{
						cursor: pointer;
						border: none;
						outline: none;
						background-color: $colorTerciary;
						color: #ffffff;
						border: 1px solid #ffffff;
						font-size: 1.5rem;
						padding: .5rem;
						line-height: .5rem;
						vertical-align: middle;
						@include border-box;
					}

					& a:hover, & .button:hover, .sub:hover .subitem{
						color: $colorPrimary;
					}
				}
			}
		}
	}
}

.videoArea {
	display: block;
	position: relative;
	width: 100%;
	height: calc(100vh - 5rem);
	background-color: $colorTerciary;
	pointer-events: none;

	& .videoareaareaoverlay {
		display: block;
		position: absolute;
		background-color: rgba($colorTerciary, .5);
		width: 100%;
		height: 100%;
	}
	& .videoareaarea {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		overflow: hidden;

		& video {
			object-fit: cover;
			width: 100vw;
			height: 100vh;
			display: inline-block;
			vertical-align: baseline;
		}
	}
}
.bg-headerinterno {
	background-color: $colorTerciary;
}
















.headermobile-btn{
	position: absolute;
	width: 100%;
	max-width: 280px;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.container-bg-headermobile{
	display: block;
	position: absolute;
	top: 0;
	overflow: hidden;
	z-index: -1;
	height: 100%;
	width: 100%;
}
$heightContainerMenuBottom: 12.75rem;
.headermobile {
	display: block;
	width: 100%;
	height: calc(100vh - #{$heightContainerMenuBottom});
	min-height: calc(100vh - #{$heightContainerMenuBottom});
	position: relative;
	will-change: background-image;
	background-image: url('/jnh/background/home.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
	background-color: $gray3 !important;
	/*animation-name: headermobile;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
	animation-duration: 30s;
	animation-direction: normal; */
}
@include keyframes('headermobile'){
	0% {
		transform: scale(1);
		background-image: url('/jnh/header/bg-header-01-mobile.jpg');
	}
	30% {
		transform: scale(1.02);
		background-image: url('/jnh/header/bg-header-01-mobile.jpg');
	}
	40% {
		transform: scale(1.02);
		background-image: url('/jnh/header/bg-header-02-mobile.jpg');
	}
	60% {
		transform: scale(1);
		background-image: url('/jnh/header/bg-header-02-mobile.jpg');
	}
	70% {
		transform: scale(1);
		background-image: url('/jnh/header/bg-header-03-mobile.jpg');
	}
	100% {
		transform: scale(1.02);
		background-image: url('/jnh/header/bg-header-03.jpg');
	}
}

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin header--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin header--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin header--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin header--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin header--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin header--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin header--smart-old(){
}