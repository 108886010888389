/*
	spanduk = Banner em Javanês
 */
.spanduk{
	display: block;
	position: relative;
	z-index: 0;
	width: 100%;
	padding: 0px;
	background-color: #EEE;
	outline: none;
	overflow: hidden;
	min-height: 80px;

	& .spanduk-canvas {
		position: relative;
		display: block;
		text-align: center;
		padding: 0;
		margin: auto;
		width: inherit;
		height: inherit;
		max-width: inherit;
		max-height: inherit;
		overflow: hidden;

		& div{
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: inherit;
			height: inherit;
			max-width: inherit;
			max-height: inherit;
			padding: 0;
			margin: 0;
			z-index: 4;
		}

		& a {
			display: block;
			width: 100%;
			height: 100%;
			max-width: inherit;
			max-height: inherit;
			padding: 0;
			margin: 0;
		}

		&  img {
			width: auto !important;
			height: 100%;
			max-width: inherit;
			max-height: inherit;
			padding: 0;
			margin: 0;
			box-shadow: 0 0 20px 4px rgba(0,0,0,0.1);
		}
	}

	& .spanduk-canvas-hidden {
		& div {
			display: none !important;
		}
	}
}

.spanduk-left, .spanduk-right{
	cursor: pointer;
	position: absolute;
	z-index: 5;
	border: none;
	outline: none;
	border-radius: 7px;
	line-height: 36px;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	padding-left: 20px;
	padding-right: 20px;
	text-align: center;
	font-size: 14px;
	color: $colorWhite;
	background-color: rgba(0,0,0,0.3);

	&:hover, &:active, &:focus{
		color: $colorPrimary;
		background-color: $colorWhite;
	}
}
.spanduk-left{
	left: 10rem;
	bottom: 1rem;
}
.spanduk-right{
	right: 10rem;
	bottom: 1rem;
}
.spanduk-left:hover,
.spanduk-right:hover,
.spanduk-slide:hover,
.spanduk-slide-pause:hover{
	opacity: 1;
}
.spanduk-legenda{
	position: absolute;
	z-index: 4;
	width: calc(100vw - 240px);
	left: 50%;
	transform: translateX(-50%);
	bottom: 10px;
	text-align: center;
	color: $gray1;
	font-weight: 600;
	font-size: 13px;
	line-height: 36px;
	text-shadow: 0 0 1px #FFF;
}
.spanduk-slide{
	display: inline-block;
	cursor: pointer;
	width: 20px;
	height: 20px;
	margin: 10px;
	background-image: url(/jnh/icones/ux-banner.png);
	background-repeat: no-repeat;
	background-size: 40px 120px;
    background-position: 0px 0px;
	opacity: 0.3;
}
.spanduk-slide-pause{
	display: inline-block;
	cursor: pointer;
	width: 20px;
	height: 20px;
	margin: 10px;
	background-image: url(/jnh/icones/ux-banner.png);
	background-repeat: no-repeat;
	background-size: 40px 120px;
    background-position: -20px 0px;
	opacity: 1;
}


/* ANIMAÇÕES */
.vir-da-direita {
	@include animation('alternate virdadireita 0.5s ease-in-out');
	animation-fill-mode: forwards;
}
@include keyframes('virdadireita'){
	100% {
		transform: translateX(0%);
		opacity: 1;
	}
}
.sair-direita {
	@include animation('alternate sairdireita 1s ease-in');
	animation-fill-mode: forwards;
}
@include keyframes('sairdireita'){
	40% {
		opacity: 0.2;
	}
	100% {
		transform: translateX(100%);
		opacity: 0.2;
	}
}

.vir-da-esquerda {
	@include animation('alternate virdaesquerda 0.5s ease-in-out');
	animation-fill-mode: forwards;
}
@include keyframes('virdaesquerda'){
	100% {
		transform: translateX(0%);
		opacity: 1;
	}
}
.sair-esquerda {
	@include animation('alternate sairesquerda 1s ease-in');
	animation-fill-mode: forwards;
}
@include keyframes('sairesquerda'){
	40% {
		opacity: 0.2;
	}
	100% {
		transform: translateX(-100%);
		opacity: 0.2;
	}
}