.bg-index-pat {
	display: block;
	width: 100%;
	position: relative;
	height: 500px;
	overflow: hidden;

	& .bg-index {
		background-image: url('/jnh/banner01.jpg');
		background-color: $gray4;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		position: absolute;

		height: 100%;
		width: 100%;

		@include animation-duration(30s);
		@include animation-name(bg_index);
		@include animation-iteration-count(infinite);
		@include animation-direction(alternate);
		animation-fill-mode: forwards;
		animation-timing-function: ease-in-out;
	}
}

@include keyframes(bg_index){
	from {
		@include scale(1);
	}
	to {
		@include scale(1.2);
	}
}

.container-condominos{
	display: block;
	width: 100%;

	& .box-left {
		display: inline-block;
		width: 40%;
		vertical-align: top;
		background-color: $colorPrimary;
		height: 520px;
		position: relative;

		& .box-item {
			display: block;
			position: absolute;
			top: 50%;
			padding-right: 20px;
			@include border-box;
			transform: translateY(-50%);

			& h2 {
				font-size: 4rem;
				line-height: 3.7rem;
				font-weight: 200 !important;
				letter-spacing: -.03em;
			}

			& h3 {
				font-size: 1.5rem;
			}
		}
	}

	& .box-right {
		height: 520px;
		display: inline-block;
		width: 60%;
		vertical-align: top;
		background-image: url(/jnh/home/condominos.jpg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
}


/* JS DATA COMPONENT */
.date-active {
	border-radius: 19px 19px 0 0 !important;
}
.date-actve {
	border-radius: 0 0 19px 19px !important;
}
.boss-js-date{
	position: relative;
	display: inline-block;
	vertical-align: top;
	outline: none;
	height: 38px;
	line-height: 36px;
	min-width: 170px;
	background-color: #FFF;
	border: 1px solid #888888;
	border-radius: 38px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
}
.boss-js-date-label{
	cursor: pointer;
	height: inherit;
	line-height: inherit;
	padding: 0px 5px;
	color: #333;
	font-size: 0.9em;
	height: inherit;
	line-height: inherit;
	position: relative;
	z-index: 3;
	text-align: center;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
}
.boss-js-date-show{
	left: -1px;
	right: -1px;
	margin-top: -4px;
	display: block;
	background-color: #FFF;
	border-left: 1px solid #AAA;
	border-right: 1px solid #AAA;
	border-bottom: 2px solid #AAA;
	position: absolute;
	z-index: 4;
	padding: 5px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
}

/* JS TIME COMPONENT */
.time-active {
	border-radius: 19px 19px 0 0 !important;
}
.time-actve {
	border-radius: 0 0 19px 19px !important;
}
.boss-js-time{
	position: relative;
	display: inline-block;
	vertical-align: top;
	outline: none;
	height: 38px;
	line-height: 36px;
	min-width: 235px;
	background-color: #FFF;
	border: 1px solid #888888;
	border-radius: 38px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
}
.boss-js-time-label{
	cursor: pointer;
	height: inherit;
	line-height: inherit;
	padding: 0px 5px;
	color: #333;
	font-size: 0.9em;
	height: inherit;
	line-height: inherit;
	position: relative;
	z-index: 3;
	text-align: center;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
}
.boss-js-time-show{
	left: -1px;
	right: -1px;
	margin-top: -4px;
	display: block;
	background-color: #FFF;
	border-left: 1px solid #AAA;
	border-right: 1px solid #AAA;
	border-bottom: 2px solid #AAA;
	position: absolute;
	z-index: 4;
	padding: 5px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
}
#render_no_mapa{
	display: block;
	width: 100%;
	background-color: #00b3ff;
}
#render_nomapa{
	display: block;
	width: 100%;
	background-color: #EEEEEE;
	height: 30vh;
	background-image: url('/jnh/map/padrao.jpg');
	background-size: cover;
	user-select: none;
	background-repeat: no-repeat;
	background-position: center;
}
.mapa_loading{
	display: flex;
	flex-direction: column;
	height: calc(100vh - 185px);
	align-items: center;
	justify-content: center;
	color: #FFF;
	background-image: url('/jnh/map/padrao.jpg');
	background-size: cover;
	user-select: none;
	background-repeat: no-repeat;
	background-position: center;
}


.bg-admcondominio {
	display: table;
	width: 100%;
	background-image: url('/jnh/home/bg-administradora.jpg');
	background-position: center;
	background-repeat: no-repeat;
	height: 55vh;
	background-size: cover;
}
#render_nomapa .mapa_loading{
	color: inherit !important;
}
.servicos {
	display: block;
	width: 100%;
	background-color: lighten($colorSecondary, 5);
	padding: 5rem 1.5rem;
	@include border-box;

	& p {
		color: #ffffff;
	}
	
	& .servicos-img {
		display: block;
		width: 5rem;
		user-select: none;
		
		& img {
			display: block;
			width: 100%;
		}
	}
}

.input-novidades:-webkit-autofill,
.input-novidades:-webkit-autofill:hover, 
.input-novidades:-webkit-autofill:focus, 
.input-novidades:-webkit-autofill:active  {
    -webkit-box-shadow:0 0 0 50px rgba(0,0,0,0) inset !important;
    -webkit-text-fill-color: white !important;
}



#ux_to_top{
	position: relative;
	top: -10px;
	left: -90%;
	z-index: 91;
}
.navegacao {
    position: sticky;
	top: 0;
	z-index: 123;
	background-color: #fff;
	overflow: auto;
	@include box-shadow('0 0 10px 5px rgba(0,0,0,.2)');

	.hand-left{
		position: absolute;
		transform: translate(-50%, -50%);
		animation-name: hand_left;
		animation-timing-function: ease-in;
		animation-iteration-count: infinite;
		animation-duration: 1s;
		animation-direction: normal;
	}
	@include keyframes('hand_left'){

		0% {
			margin-left: 0;
		}

		50% {
			margin-left: 20%;
		}
		0% {
			margin-left: 0;
		}
	}
	
	.hand-right{
		position: absolute;
		transform: translate(-50%, -50%);
		animation-name: hand_right;
		animation-timing-function: ease-in;
		animation-iteration-count: infinite;
		animation-duration: 1s;
		animation-direction: normal;
	}
	@include keyframes('hand_right'){

		0% {
			margin-right: 0;
		}

		50% {
			margin-right: 20%;
		}
		0% {
			margin-right: 0;
		}
	}

	& .container{
		width: 100%;
		margin: 0 auto;
		
		& button, a {
			padding: .5rem;
			@include border-box;
			color: $gray1;
			font-weight: 400;
			transition: all .13s;

			&:hover, &:active, &:focus {
				font-weight: 600;
			}
		}
	}


	& .flex-item1 {
		flex: 1;
	}
	
	& .flex{
		display: flex;
		width: 100%;
		& .item{
			margin: 5px;
			text-align: center;
			font-size: 1rem;
		}
	}
}


.navegacao::-webkit-scrollbar{width: .5rem; height: .5rem;}
.navegacao::-webkit-scrollbar-track-piece{background-color: $gray5;}
.navegacao::-webkit-scrollbar-thumb:vertical{height: .1rem; background-color: $colorPrimary;}
.navegacao::-webkit-scrollbar-thumb:horizontal{width: .1rem; background-color: $colorPrimary;}
.navegacao::-ms-clear {width: 0; height: 0;}
.navegacao::-ms-reveal {width: 0; height: 0;}