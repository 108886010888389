$formsimple_inputHeight: 35;
$formsimple_inputHeightMin: 35;
$formsimple_inputBorder: 1;
$formsimple_inputFontSize: 1;
$formsimple_inputBorderCheckbox: 1;
$formsimple_inputBorderColor: $gray2;
$formsimple_inputBackgroundColor: #fff;
$formsimple_inputBorderRadius_a: .6;
$formsimple_inputBorderRadius_b: .6;
$formsimple_inputBorderRadius_c: .6;
$formsimple_inputBorderRadius_d: .6;
$formsimple_inputBorderRadiusCheckbox: 30;
$formsimple_inputPdLeft: 20;
$formsimple_inputPdRight: 20;
$formsimple_labelColor: $gray1;

@mixin formsimple-font(){
	font-family: inherit  !important;
	font-size: #{$formsimple_inputFontSize}rem !important;
	font-weight: 400 !important;
	color: $gray1 !important;
}

.formulario-titulo {
	@include formsimple-font();
	font-size: 16px !important;
}

@mixin prefix($propriedade, $valor){
	-webkit-#{$propriedade}: $valor !important;
	-moz-#{$propriedade}: $valor !important;
	-ms-#{$propriedade}: $valor !important;
	-o-#{$propriedade}: $valor !important;
	#{$propriedade}: $valor !important;
}

@import 'inputs.scss';
@import 'upload.scss';
@import 'textarea.scss';
@import 'select.scss';
@import 'radio.scss';
@import 'checkbox.scss';
@import 'selectmultiple.scss';