.busca-filtros{
	display: block;
	width: 100%;
	padding: 1rem;
	border: 1px solid $gray1;
	@include border-box;

	& h2 {
		text-align: center;
		border-bottom: 1px solid $gray1;
	}
}
#busca_container{
	border-top: 1px solid rgba(255,255,255, .4);
}
.busca-container-busca {
	display: block;
	position: sticky;
	top: 0;
	z-index: 20;
}
.busca-container-buscar{
	position: fixed;
	z-index: 2115;
	top: 103px;
	left: 0;
}



.b-flat2 {

    line-height: 0.3rem;
	&:hover, &:focus, &:active, &:visited {
		line-height: 0.3rem;
	}
}
.buscar-container-filtro{
	display: block;
	width:100%;
	padding: 2rem;
	@include border-box;
	background-color: #f3f5f8;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	height: 100vh;
	min-height: 300px;
	overflow: auto;
	z-index: 494949840;
}
.busca-container-busca-cidade {
	position: fixed;
	top: 17rem;
	// margin-left: 1rem; tirei
	overflow: auto;
	height: 80vh;
	background-color: #fff;
	padding: 1rem;
	@include border-box;
	& #check_pronto_morar{
		padding: 2.5rem 0;
	}
}
.busca-container-imoveis {
	padding: 1rem;
	margin-left: 25%;
	margin-top: 11rem;
	@include border-box;
	background-color: white !important;
    min-height: 840px;
}
@media all and (max-width: 1023px){

	.busca-container-buscar {
		background-color: #282828;
		position: relative;
		top: 0 !important;
		padding: 0 1rem;
		box-sizing: border-box;
	}
	.busca-container-imoveis {
		margin: 0;
		padding: 1rem !important;
	}
	#busca_container {
		display: block;
		width: 100%;
		overflow: auto;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		padding-bottom: 7rem;
		@include border-box;
		z-index: 99999;

		& .busca_btn_close {
			position: absolute;
			top: 1rem;
			right: 3rem;
			line-height: inherit;
		}
	}

	.busca-filtrosssss{
		overflow: auto;
		height: 100vh;
		position: fixed;
		top: 0;
		z-index: 10;
		background: #ffffff;
		left: 0;
		z-index: 99999;

		& .busca-filtros {
			padding-top: 4rem;
			padding-bottom: 7rem;
			border: 0 solid $gray1;
		}
		& .busca_btn_close {
			position: absolute;
			top: 1rem;
			right: 3rem;
			line-height: inherit;
		}
	}
}
.busca_container-fixed{
	display: block;
	position: fixed;
	z-index: 10;
}
#render-busca {
	padding: 1rem;
	@include border-box;
	background-color: white !important;
}
.container-imoveis-busca {
	display: block;
	width: 100%;
	overflow: auto;
	height: 100vh;
	min-height: 650px;
}






.busca-container-busca-cidade-mobile{
	z-index: 92091250125;
	top:0;
	height: 100vh;
	left: 0;
	padding: 2rem !important;
	margin: 0;
	padding-bottom: 10rem;
	@include border-box;
}


.creccss select{
	color: $gray1;
}

.btn-x{
background-color: #ff7700 !important;
border-radius: 6px;
color: white;
font-weight: 600;
border-color: #ff7700 !important;
border: 0 !important;
height: 2rem;
margin-top: 0.6rem;
	&:hover{
		background-color: darken(#ff7700,10) !important;
		color: white !important;
	}
}
@media all and (max-width: 1024px){
	// alterações do intruso
	.formsimple-mob{
		background-color: #282828 !important;
		
		& select{
			color: white !important;
			background-color: #282828 !important;
			
		}
		& .label{
			color: white !important;
			background-color: #282828 !important;
			border-color: #343434 !important;
			border-radius: 6px !important;
			
		}
		& .component {
			background-color: #282828 !important;
			border-bottom: 1px solid #343434 !important;
			border-left: 1px solid #343434 !important;
			border-right: 1px solid #343434 !important;

			& .items {
				background-color: #282828 !important;
				font-weight: 600 !important;
				
				& * {
					color: white !important;
					
				}
			}

			& .botoes {
				display: block;
				width: 100%;
				background-color: #ff7700 !important;
				height: 2.5rem;
				border-top: 0 !important;
				& .undo{
					display: inline-block;
					width: 50%;
					vertical-align: top;
				}

				& .ok{
					display: inline-block;
					width: 50%;
					border-left: solid 1px white !important;
					vertical-align: top;
				}

				& button {
					display: block;
					margin: 0;
					outline: none;
					background-color: transparent;
					color: white;
					font-weight: 600 !important;
					height: inherit;
					&:hover{
						background-color: darken(#ff7700,10) !important;
					}
				}
			}
		}
	}

	.formsimple-mob::before{
		content: '';
		pointer-events: none;
		position: absolute;
		top: 45%;
		right: 20px;
		display: block;
		width: 0; 
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 5px solid #AAA;
		z-index: 999;
		-webkit-animation: all 0.15s ease-in-out;
		-moz-animation: all 0.15s ease-in-out;
		-o-animation: all 0.15s ease-in-out;
		-ms-animation: all 0.15s ease-in-out;
		animation: all 0.15s alternate;
		-webkit-transition: all 0.15s ease-in-out;
		-moz-transition: all 0.15s ease-in-out;
		-o-transition: all 0.15s ease-in-out;
		-ms-transition: all 0.15s ease-in-out;
		transition: all 0.15s ease-in-out;
	}
	.btn-r-bmob{
		background-color: #ff7700 !important;
		border-radius: 6px;
		color: white;
		font-weight: 600;
		border-color: #ff7700 !important;
		&:hover{
			background-color: darken(#ff7700,10) !important;
		}
	}
	.btn-l-bmob{
	background-color: #404040 !important;
	padding-left: 1rem;
	border-radius: 6px;
	color: white;
	font-weight: 600;
	&:hover{
		background-color: darken(#404040,10) !important;
	}
	}
	.label-mob{
		border-color: $gray2;
		background-color: #282828;
		color: white !important;
		select{
			color: white !important;
			background-color: #282828 !important;
			border-color: #282828 !important;
		}


	}
	.pd-top-asc{
		padding-top: 1rem;
	}
	.hr-mob{
		background-color: #ff7700 !important;
		margin-bottom: 0 !important;
		margin-top: 0 !important;
		padding-top: 0.2rem;
	}
	
	// fim alterações instruso
}

@media all and (min-width: 1024px) and (max-width: 1370px){
	.busca-container-busca-cidade {
		position: fixed;
		top: 17rem;
		// margin-left: 1rem; tirei
		overflow: auto;
		height: 80vh;
		background-color: #fff;
		padding: 1rem;
		@include border-box;
		& #check_pronto_morar{
			padding: 2rem 0 8rem 0;
		}
	}
}