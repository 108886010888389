.nav {
	display: none;
	width: 100%;
	position: fixed;
	top: 0;
	bottom: 0;
	background: $colorTerciary; 
	z-index: 9999999999999999999999;
	color: white;

	& button {
		margin-right: 1rem;

		& i {
			line-height: inherit;
		}
	}

	& .nav-flow {
		overflow: auto;
		min-height: 50vh;
		height: 100vh;
	}

	& .nav-titulo {
		color: $colorPrimary;
		font-size: 1.3rem;
		font-weight: 500;
		padding: 1.5rem 0;
		@include border-box;
		text-transform: uppercase;
	}

	& .nav-menu {
		color: #ffffff;
		font-size: 1.2rem;
		margin: 0;
		padding: .6rem 0;
		@include border-box;

		&:hover {
			font-weight: 400;
			color: lighten($colorPrimary, 8);
		}
	}
}

@media all and (max-width: 1023px){
	.nav{
		& .content {
			padding-bottom: 10rem !important;
		}
	}
}