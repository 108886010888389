/* MINIATURA 1 */
.miniatura-imovel {
	display: inline-block;
	vertical-align: top;
	/* position: relative; */

	& .miniatura-imovel-img{

		display: block;
		width: 100%;
		/* padding-top: 60%;
		overflow: hidden; */
		background-color: $gray1; 
		position: relative;
		user-select: none;

		& img{
			display: block;
			width: 100%;
			/* position: absolute;
			left: 0;
			top: -30%; */
			
			/* object-fit: cover;
			object-position: 0 59px;  */
			
			//clip: rect(49px, 340px, 300px, 0);
		}

		@include box-shadow('0 0 10px 5px rgba(0,0,0,.1)');

		& .miniatura-imovel-promocao{
			position: absolute;
			top: 0;
			left: 0;
			background-color: #ff7700;
			color: white;
			padding: .3rem 1rem;
			@include border-box;
		}
			
		& .miniatura-arrow{
			display: block;
			top: 50%;
			transform: translateY(-50%);
			position: absolute;
			padding: 0;
			@include border-box;

			& button {
				display: block;
				line-height: 3rem;
				width: 3rem;
				border: none;
				padding: 0;
				margin: 0;
				outline: none;
				cursor: pointer;
				font-size: 2rem;
			}
		}

		& .arrow-left{
			left: 0;
		}
		& .arrow-right{
			right: 0;
		}
	}
	& .miniatura-imovel-img2{
		border-radius: .2rem .2rem 0 0;
		display: block;
		width: 100%;
		overflow: hidden;
		padding-top: 95%;
		background-color: $gray1; 
		position: relative;
		user-select: none;
		& img{
			transition: all .30s;
			display: block;
			width: 100%;
			position: absolute;
			left: 0;
			top: 0;
			&:hover{
				transform: scale(1.05);
			}
		}
		& .miniatura-arrow{
			display: block;
			top: 50%;
			transform: translateY(-50%);
			position: absolute;
			padding: 0;
			@include border-box;

			& button {
				display: block;
				line-height: 3rem;
				width: 3rem;
				border: none;
				padding: 0;
				margin: 0;
				outline: none;
				cursor: pointer;
				font-size: 2rem;
			}
		}

		& .arrow-left{
			left: 0;
		}
		& .arrow-right{
			right: 0;
		}
	}

	& .miniatura-imovel-img-video {
		@extend .miniatura-imovel-img2;

		padding-top: 70%;
		position: relative;
		
		& img {
			top: -8px;
		}

		& .miniatura-video-player {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			font-size: 4rem;
			color: #ffffff;
		}
	}

	& .miniatura-imovel-status{
		position: absolute;
		top: 0;
		left: 0;
		background-color: #ff7700;
		color: white;
		padding: .3rem 1rem;
		@include border-box;
	}


	& .miniatura-imovel-btns-icons {
		color: $gray1;
	}

	& .miniatura-informacoes {
		text-align: center;
		padding: 0 .5rem;
		@include border-box;

		& .left {
			color: $gray1;
			font-size: 1.2rem;
		}
		& .right {
			color: $gray1;
			font-size: .9rem;
    		font-weight: 300;
		}
	}

	& .miniatura-cidade-estado{
		display: inline-block;
		width: calc(100% - 3rem);
	//	height: 1.3rem;
		overflow: hidden;
		vertical-align: middle;
		text-transform: uppercase;
		margin-bottom: .5rem;

		font-size: 1.1rem;
		line-height: 1.2rem;
		font-weight: 700;
		color: $gray1;
	}
	& .miniatura-acao-tipo{
		font-size: 1rem;
		font-weight: 500;
		color: $gray2;
	}

	& .miniatura-container-id{
		display: inline-block;
		width: 5rem;
		vertical-align: middle;
		text-align: right;
	}

	& .miniatura-imovel-endereco {
		font-style: oblique;
	}

	& .miniatura-imovel-preco {
		padding: .5rem;
		@include border-box;
		background-color: $gray2;
		font-size: .91rem;
	}

	& .miniatura-p {
		color: $gray1;
		font-size: .9rem;
	}

	& .miniatura-imovel-detalhe{
		@include box-shadow('0 0 10px 5px rgba(0,0,0,.1)');
		display: block;
		width: 100%;
		padding: 1rem;
		background-color: $gray5;
		border-radius: 0px 0px 7px 7px;
		@include border-box;
		position: relative;

		& .miniatura-btn-favorito {
			position: absolute;
			bottom: 1rem;
			right: 2rem;
			height: 40px;
		}

		& .miniatura-imovel-btns-icons{
			display: block;
			width: 100%;
			overflow: hidden;
			min-height: 22px;
			height: 3.3rem;

			& .miniatura-imovel-icone {
				display: inline-block;
				width: 25%;
				vertical-align: middle;
			}

			& .miniatura-informacoes{

				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					padding-right: 0;
				}
			}
		}
	}
}


.miniatura-imovel-empreendimento {
	display: inline-block;
	vertical-align: top;

	& .miniatura-imovel-img{

		display: block;
		width: 100%;
		overflow: hidden;
		padding-top: 60%;
		background-color: $gray1; 
		position: relative;
		user-select: none;

		& img{
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
		}
		@include box-shadow('0 0 10px 5px rgba(0,0,0,.1)');

		& .miniatura-arrow{
			display: block;
			top: 50%;
			transform: translateY(-50%);
			position: absolute;
			padding: 0;
			@include border-box;

			& button {
				display: block;
				line-height: 3rem;
				width: 3rem;
				border: none;
				padding: 0;
				margin: 0;
				outline: none;
				cursor: pointer;
				font-size: 2rem;
			}
		}

		& .arrow-left{
			left: 0;
		}
		& .arrow-right{
			right: 0;
		}
	}
	
	& .miniatura-imovel-img-video {
		@extend .miniatura-imovel-img2;

		padding-top: 70%;
		position: relative;
		
		& img {
			top: -8px;
		}

		& .miniatura-video-player {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			font-size: 4rem;
			color: #ffffff;
		}
	}

	& .miniatura-imovel-status{
		position: absolute;
		top: 0;
		left: 0;
		background-color: #ff7700;
		color: white;
		padding: .3rem 1rem;
		@include border-box;
	}
	& .miniatura-imovel-btns-icons {
		color: $gray1;
	}

	& .miniatura-informacoes {
		text-align: center;
		padding: 0 .5rem;
		@include border-box;

		& .left {
			color: $gray1;
			font-size: 1.2rem;
		}
		& .right {
			color: $gray1;
			font-size: .9rem;
    		font-weight: 300;
		}
	}

	& .miniatura-cidade-estado{
		display: inline-block;
		width: calc(100% - 3rem);
	//	height: 1.3rem;
		overflow: hidden;
		vertical-align: middle;
		text-transform: uppercase;
		margin-bottom: .5rem;

		font-size: 1.1rem;
		line-height: 1.2rem;
		font-weight: 700;
		color: $gray1;
	}
	& .miniatura-acao-tipo{
		font-size: 1rem;
		font-weight: 500;
		color: $gray2;
	}

	& .miniatura-container-id{
		display: inline-block;
		width: 5rem;
		vertical-align: middle;
		text-align: right;
	}

	& .miniatura-imovel-endereco {
		font-style: oblique;
	}

	& .miniatura-imovel-preco {
		padding: .5rem;
		@include border-box;
		background-color: $gray2;
		font-size: .91rem;
	}

	& .miniatura-p {
		color: $gray1;
		font-size: .9rem;
	}

	& .miniatura-imovel-detalhe{
		@include box-shadow('0 0 10px 5px rgba(0,0,0,.1)');
		display: block;
		width: 100%;
		padding: 1rem;
		background-color: $gray5;
		border-radius: 0px 0px 7px 7px;
		@include border-box;
		position: relative;

		& .miniatura-btn-favorito {
			position: absolute;
			bottom: 1rem;
			right: 2rem;
			height: 40px;
		}

		& .miniatura-imovel-btns-icons{
			display: block;
			width: 100%;
			overflow: hidden;
			min-height: 22px;
			height: 3.3rem;

			& .miniatura-imovel-icone {
				display: inline-block;
				width: 25%;
				vertical-align: middle;
			}

			& .miniatura-informacoes{

				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					padding-right: 0;
				}
			}
		}
	}
}

/* SÓ SE APLICA NO PC */
@media all and (min-width: 1024px) {
	.iwidth-100{
		width: 100% !important;
	}
	.iwidth-50{
		width: 50% !important;
	}
	.iwidth-33-3{
		width: 33.333333% !important;
	}
	.iwidth-25{
		width: 25% !important;
	}
	.iwidth-20{
		width: 20% !important;
	}
}

// Calibrar miniatura nos dispositivos moveis
@media all and (min-width: 1024px) and (max-width: 1500px) {
	.miniatura-imovel {

		& .miniatura-imovel-img{

			& img{
				//object-position: 0 2.8rem;
			}
		}
	}
}
@media all and (min-width: 485px) and (max-width: 1023px) {
	.miniatura-imovel {

		& .miniatura-imovel-img{

			& img{
				//object-position: 0 7rem;
			}
		}
	}
}
@media all and (min-width: 320px) and (max-width: 485px) {
	.miniatura-imovel {

		& .miniatura-imovel-img{

			& img{
				//object-position: 0 4.4rem;
			}
		}
	}
}
@media all and (max-width: 320px) {
	.miniatura-imovel {

		& .miniatura-imovel-img{

			& img{
				//object-position: 0 52px;
			}
		}
	}
}