.documentos-box {
	display: block;
	padding: 1rem;
	background-color: #fff;
	@include border-box;
	@include border-radius(1rem);
	@include box-shadow('0 0 10px 5px rgba(0,0,0,.1)');
	height: 18rem;

	& img {
		display: block;
		width: 4rem;
		margin: auto;
	}
}

@media all and (max-width: 1023px){

	.documentos-box{
		width: 80%;
	}
}