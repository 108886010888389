/* SCROLL-BAR */
::-webkit-scrollbar{width: 10px; height: 10px;}
::-webkit-scrollbar-track-piece{background-color: #FFF;}
::-webkit-scrollbar-thumb:vertical{height: 10px; background-color: $gray3;}
::-webkit-scrollbar-thumb:horizontal{width: 10px; background-color: $gray3;}
::-ms-clear {width: 0; height: 0;}
::-ms-reveal {width: 0; height: 0;}

.ux_estilos{
	background-image: url(/jnh/background/estilos.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	display: table;
	width: 100%;
}
.ux_estilos_box{
	display: inline-block;
	text-align: center;
	padding: 5px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
}
.ux_estilos_box_ajuste{
	margin-left: -5px;
	margin-right: -5px;
}
.ux_estilos_height{
	width: 101%;
	height: 14vw;
	background-color: #282828;
	border-radius: 2px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.shadow-box{
	// @include filter('drop-shadow(0 2px 6px rgba(0,0,0,.3))');
	@include box-shadow('0 0 20px 5px rgba(0,0,0,.2)');
}
.shadow-box2{
	// @include filter('drop-shadow(0 2px 6px rgba(0,0,0,.3))');
	@include box-shadow('0 9px 14px 6px rgba(0,0,0,.2)');
}
.ux_estilos_center{
	display: table-cell;
	vertical-align: middle;
	width: 100vw;
	height: 14vw;
	font-weight: normal;
	font-size: 20px !important;
	color: #FFF !important;
	background-color: rgba(0,0,0,0.3);
}
.ux_estilos_center:hover, .ux_estilos_center:active, .ux_estilos_center:focus{
	font-size: 20px !important;
	color: #FFF !important;
}

@media (max-width: 1024px) {
	.ux_estilos_height{
		height: 34vw;
	}
	.ux_estilos_center{
		height: 34vw;
}
}

.formanuncie{
	display: block;
	width: 100%;

	& .left, & .right{
		display: block;
		vertical-align: middle;
		color: $gray1;
	}

	& .left{
		width: 100%;
		text-align: left;
		font-size: 0.9rem;
		padding-bottom: .2rem;
		@include border-box;
	}

	& .right{
		width: 100%;
		@include border-box;
	}
}

.buscarodape{
	position: fixed;
	bottom: 0;
	left: 0;
    width: 100%;
	background-color: $colorTerciary;
	color: #fff;
	z-index: 12345;
	line-height: 1.1rem !important;
	padding: .3rem !important;

	& a, .b-primary {
	 	line-height: 1.1rem !important;
	 	background: transparent;
	 	padding: .3rem !important;
		color: #fff;

		&:active, &:focus, &:hover {
			@extend .b-primary;
		}
	}

	& a, .gray1 {
		color: #fff;
	}

	& .b-white {
		background-color: transparent !important;
		color: $colorPrimary !important;
		border-color: $colorTerciary;
	}
}

.ho-title{
	font-size: 1.3rem;
}

.ho-subtitle{
	font-size: 1.1rem;
}
.cor-caixa {
	color: #0066b3;
}

.ho-txt{
	font-size: 1rem;
}

.ho-mini{
	font-size: 0.9rem;
}
.titleform{
	display: block;
	padding: 0.1rem 0rem 0.3rem 0rem;
	font-size: 1.1rem;
	font-weight: 500;
}

.font-style {
	font-family: 'Webfont-style';
}

html{
	width: 100%;
	height: 100%;
	touch-action: pan-y;
	-ms-touch-action: pan-y;
}

body{
	font-family: 'WebNimbus Sans L', sans-serif;
	background-color: #f5f5f5;
	color: $gray2;
	font-weight: 200;
	margin: 0;
	padding: 0;
	font-size: 1rem;
	touch-action: pan-y;
	-ms-touch-action: pan-y;
}

h1, .h1{
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 1.5em;
	padding: 0;
	margin: 0.8rem 0 0.8rem 0;
	color: $gray1;
}

h2, .h2{
	font-size: 1.4rem;
	font-weight: 500;
	line-height: 1.4em;
	padding: 0;
	margin: 0.8rem 0 0.8rem 0;
	color: $gray1;
}

h3, .h3{
	font-size: 1.3rem;
	font-weight: 500;
	line-height: 1.3em;
	padding: 0;
	margin: 0.8rem 0 0.8rem 0;
}


h4, .h4{
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.1em;
	color: $colorPrimary;
	padding: 0;
	margin: 0.8rem 0 0.8rem 0;
}

a{
	font-size: 1rem;
	text-decoration:none;
	cursor:pointer;
	color: $gray1;

	&:hover {

		color: $colorPrimary;
	}
}

div{
	line-height: 1em;
	margin: auto;
}

p{
	font-weight: 300;
	color: $gray1;
	line-height: 1.4em;
	margin: 10px 0;
	font-size: 1rem;
}

.icon-gradiente {
	@include gradient('left, #{lighten($colorSecondary, 5%)} 30%, #{darken($colorPrimary, 8%)}' 60%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}


.detail-line::after {
	display: block;
	width: 2rem;
	content: '';
	height: .2rem;
	background-color: $colorPrimary;
}

.detail-dialog:after {
	display: block;
	width: 5rem;
	content: '';
	height: .3rem;
	margin-top: 1rem;
	background-color: $colorPrimary;
}
.padrao-icone {
	& i {
		font-size: 45px;
		width: 70px;
		height: 70px;
		line-height: 70px;
		@include border-radius(50%);
		text-align: center;
		color: $gray1;
		border: 2px solid $gray1;
	}
}

.container-fixo {
	position: fixed !important;
	bottom: 20px !important;
	right: 20px !important;
	z-index: 9997;
}

.b-tohome {}

.strong, strong, b{
	font-weight: 600 !important;
}

.danger {
	color: red !important;
}
.primary{
	color: $colorPrimary !important;
}
.secondary{
	color: $colorSecondary !important;
}
.terciary{
	color: $colorTerciary !important;
}
.red{
	color: $colorDanger !important;
}
.black{
	color: $gray1 !important;
}
.gray1{
	color: $gray1 !important;
}
.gray2{
	color: $gray2 !important;
}
.gray3{
	color: $gray3 !important;
}
.gray4{
	color: $gray4 !important;
}
.gray5{
	color: $gray5 !important;
}
.bg-c-white {
	background-color: #ffffff !important;
}
.bg-white{
	display: table;
	width: 100%;
	background-color: #ffffff;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray1{
	display: table;
	width: 100%;
	background-color: $gray1;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray2{
	display: table;
	width: 100%;
	background-color: $gray2;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray3{
	display: table;
	width: 100%;
	background-color: $gray3;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray4{
	display: table;
	width: 100%;
	background-color: $gray4;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray5{
	display: table;
	width: 100%;
	background-color: $gray5;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-primary{
	display: table;
	width: 100%;
	background-color: $colorPrimary;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}

.bg-primary-g{
	display: table;
	width: 100%;
	@include gradient('right, #{lighten($colorPrimary, 5%)} 5%, #{darken($colorPrimary, 8%)}' 60%);
}

.bg-secondary{
	display: table;
	width: 100%;
	background-color: $colorSecondary;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-terciary{
	display: table;
	width: 100%;
	background-color: $colorTerciary;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}

.text-left2{
	text-align: left !important;
	margin: 0;
}
.bg-danger{
	display: table;
	width: 100%;
	background-color: $colorDanger;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.box-lateral {
	margin-left: -30px;
	margin-right: -30px;
}
.box-lateral-20 {
	margin-left: -20px;
	margin-right: -20px;
}

.bg-gradiente{
	display: table;
	width: 100%;
	background: -webkit-gradient(linear, left top, right top, from($claro), to($escuro)) no-repeat;
	background: -moz-linear-gradient(left, $claro, $escuro);
	filter:progid:DXImageTransform.Microsoft.Gradient(GradientType=1, StartColorStr=$claro, EndColorStr=$escuro);
}

.pd-center-inta{
	padding: .5rem;
	@include border-box;
}
.blockinsta {
	@extend .block;
	display: contents;
}

.bordadetalhada {
	border-right: 1px solid $colorPrimary;
}



.none {
	display: none !important;
}
@media (max-width: 1024px) {
	.bordadetalhada {
		border-color: rgba(0,0,0,0);
	}
	.menu-mobile button{
		background-color: transparent !important;
	}
	.menu-mobile .bcentral {
		background-color: $gray2 !important;
	}
}
/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin site--large(){
	html{
		font-size: 1.15vw;
	}
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin site--medium(){
	html{
		font-size: 1.3vw;
	}
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin site--tablet-landscape(){

	.blockinsta {
		display: inline-block;
	}

	.container-fixo {
		bottom: 70px !important;
	}
	html{
		font-size: 1.7vw;
	}

}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin site--tablet-portrait(){
	.blockinsta {
		display: inline-block;
	}
	.container-fixo {
		bottom: 70px !important;
	}
	html{
		font-size: 2vw;
	}
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin site--smart-landscape(){
	.blockinsta {
		display: inline-block;
	}
	.container-fixo {
		bottom: 75px !important;
	}
	html{
		font-size: 2vw;
	}
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin site--smart-portrait(){
	.blockinsta {
		display: inline-block;
	}
	.container-fixo {
		bottom: 55px !important;
	}

	html{
		font-size: 3.75vw;
	}
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin site--smart-old(){
	.blockinsta {
		display: inline-block;
	}
	.container-fixo {
		bottom: 55px !important;
	}

	html{
		font-size: 3.75vw;
	}
}