#containergaleria_imagens{
	position: relative;
	& .miniatura-imovel-promocao{
		position: absolute;
		top: 10%;
		left: 10%;
		background-color: #ff7700;
		color: white;
		padding: .3rem 1rem;
		@include border-box;
		z-index: 99;
	}
}

@media (max-width:1023px){
	#containergaleria_imagens{
		.miniatura-imovel-promocao{
			position: absolute;
			left: 12.5%;
		}
	}
} 

/* UX GALERIA */
.ux-galeria{
	display: block;
	position: relative;
	width: 100%;
	padding: 0px;
	outline: none;
	overflow: hidden;
}
.ux-galeria-borda{
	background-color: white;
	border-bottom: 3px solid #282828;
}
.galeria-full-screen{
	border-radius: 0;
	position: fixed !important;
	z-index: 9998 !important;
	top: 0px !important;
	left: 0px !important;
	right: 0px !important;
	bottom: 0px !important;
}
.ux-galeria-canvas{
	position: relative;
	display: block;
	background-color: white;
	text-align: center;
	padding: 0px;
	margin: 0px;
	width: inherit;
	height: inherit;
	min-height: 150px;
	max-width: inherit;
	max-height: inherit;
	overflow: hidden;
	perspective: 100vw;
}
.ux-galeria-canvas div{
	display: table-cell;
	vertical-align: middle;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	width: inherit;
	height: inherit;
	max-width: inherit;
	max-height: inherit;
	padding: 0px;
	margin: 0px;
}
.ux-galeria-canvas-hidden div{
	display: none;
}
.ux-galeria-canvas img{
	max-width: inherit;
	max-height: inherit;
	padding: 0px;
	margin: 0px;
	box-shadow: 0px 0px 20px 4px rgba(0,0,0,0.1);
}
.ux-galeria-top{
	position: absolute;
	z-index: 4;
	display: block;
	top: 0px;
	right: 0px;
	left: 0px;
	text-align: right;
}
.ux-galeria-fullscreen{
	display: inline-block;
	vertical-align: top;
	cursor: pointer;
	width: 20px;
	height: 20px;
	margin: 5px;
}
.ux-galeria-fullscreen::before{
	display: block;
	color: #FFF;
	content: "\f065";
	text-shadow: 0px 0px 4px rgba(0,0,0,0.5);
	font-family: "Objetiva Software Light";
	font-size: 20px;
	text-align: center;
	transform-origin: center center;
	-webkit-animation: all 0.25s ease-in-out;
	-moz-animation: all 0.25s ease-in-out;
	-o-animation: all 0.25s ease-in-out;
	-ms-animation: all 0.25s ease-in-out;
	animation: all 0.25s alternate;
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}
.ux-galeria-fullscreen-exit{
	display: inline-block;
	cursor: pointer;
	width: 20px;
	height: 20px;
	margin: 5px;
}
.ux-galeria-fullscreen-exit::before{
	display: block;
	color: #FFF;
	content: "\f066";
	text-shadow: 0px 0px 4px rgba(0,0,0,0.5);
	font-family: "Objetiva Software Light";
	font-size: 20px;
	text-align: center;
	transform-origin: center center;
	-webkit-animation: all 0.25s ease-in-out;
	-moz-animation: all 0.25s ease-in-out;
	-o-animation: all 0.25s ease-in-out;
	-ms-animation: all 0.25s ease-in-out;
	animation: all 0.25s alternate;
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}
.ux-galeria-slide{
	display: inline-block;
	vertical-align: top;
	cursor: pointer;
	width: 20px;
	height: 20px;
	margin: 5px;
}
.ux-galeria-slide::before{
	display: block;
	color: #FFF;
	content: "\f04b";
	text-shadow: 0px 0px 4px rgba(0,0,0,0.5);
	font-family: "Objetiva Software Light";
	font-size: 20px;
	text-align: center;
}
.ux-galeria-slide-pause{
	display: inline-block;
	cursor: pointer;
	width: 20px;
	height: 20px;
	margin: 5px;
}
.ux-galeria-slide-pause::before{
	display: block;
	color: #FFF;
	content: "\f04c";
	text-shadow: 0px 0px 4px rgba(0,0,0,0.5);
	font-family: "Objetiva Software Light";
	font-size: 20px;
	text-align: center;
}
.galeria-full-screen .ux-galeria-canvas{
	background-color: #000 !important;
}
.ux-galeria-nav{
	background-color: rgba(0,0,0,0);
	position: absolute;
	display: block;
	z-index: 4;
	left: 0px;
	right: 0px;
	bottom: 0px;
	padding: 5px 10px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
}
.ux-galeria-left{
	display: inline-block;
	float: left;
	cursor: pointer;
	width: 40px;
	height: 40px;
	font-size: 1.5rem;
	opacity: 0.8;
	background-repeat: no-repeat;
	background-size: 40px 120px;
	background-position: 0px -80px;
}
.ux-galeria-right{
	display: inline-block;
	float: right;
	cursor: pointer;
	width: 40px;
	font-size: 1.5rem;
	height: 40px;
	opacity: 0.8;
	background-repeat: no-repeat;
	background-size: 40px 120px;
	background-position: 0px -40px;
}

.ux-galeria-legenda{
	width: calc(100% - 100px);
	text-align: center;
	padding-left: 5px;
	text-shadow: 0px 1px 2px rgba(0,0,0,0.5), 1px 0px 2px rgba(0,0,0,0.5);
	color: #FFF;
	font-weight: 600;
	display: inline-block;
	vertical-align: middle;
	line-height: 30px;
	font-size: 14px;
}
.ux-galeria-left:hover,
.ux-galeria-right:hover,
.ux-galeria-fullscreen:hover,
.ux-galeria-fullscreen-exit:hover,
.ux-galeria-slide:hover,
.ux-galeria-slide-pause:hover{
	opacity: 1;
}
.ux-galeria-side{
	overflow: auto;
	background-color: #dadada;
	height: 20px;
}
.ux-galeria-side img{
	display: block;
	float: left;
	width: 50%;
	max-width: 130px;
}
