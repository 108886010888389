.imovel-ver-contato {
	display: block;
	width: 100%;
	//background-color: blue;


	& .imovel-ver-corretor-img {
		display: inline-block;
		width: 4rem;
		vertical-align: middle;

		& img {
			@include border-radius(50%);
			display: block;
			width: 100%;
			user-select: none;
		}
	}

	& .imovel-ver-corretor-detalhes {
		display: inline-block;
		width: calc(100% - 4rem);
		vertical-align: middle;
		padding-left: 1rem;
		@include border-box;

		& h3 {
			color: $gray1;
		}

		& img {
			display: inline-block;
			width: 1.7rem;
			vertical-align: middle;
		}
	}

	// DIV FORMULÁRIO
	& .imovel-ver-formulario {
		display: block;
		width: 100%;
	}
}

.form-contato-fix {
	position: fixed;
	width: calc(25% - 6rem);
	top: 20px;
	z-index: 10;
	@include box-shadow('0 4px 11px -2px rgba(0,0,0, .8)');
	transition: box-shadow 1s;
}

.container_btn_ver_prec{
	display: block;
	width: 100%;
	text-align: center;

	& .btn_form_ver_preco{
		background-color: $colorPrimary;
		color: white;
		font-size: 1rem;
		font-weight: 600;
		padding: .5rem 2rem;
		border: none;
		border-radius: .5rem;
		margin: auto;
		cursor: pointer;
		transition: background-color .2s ease-in-out;

		& i{
			font-weight: 300;
		}

		&:hover{
			background-color: darken($colorPrimary, 3);
		}
	}
}

.container_form_ver_preco{
	background-color: white;
	display: block;
	width: inherit;
	padding: 2rem;
	border-radius: 1rem;

	& h3{
		color: $colorTerciary;
		font-size: 1.3rem;
		font-weight: 600;
	}
}

@media all and (min-width: 1023px){

	.form-contato-fix {
		width: calc(25% - 3rem);
	}
}