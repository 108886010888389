/*
	PAGINA INTERNA DO LANÇAMENTO
	-SESSÕES-	
*/
//@import 'lancamentos-ver/acao-tipo-titulo.scss';
	
.plantas {
	& .plantas-grupo{
		vertical-align: top;
		position: relative;
		padding: 1rem;
		@include border-box;

		& .bg{
			display: block;
			width: 100%;
			border: 1px solid $gray5;
			//padding: 10px;
			@include border-box;
		}

		& .left{
			display: inline-block;
			vertical-align: top;
			width: 40%;
			position: relative;
		}
		& .right{
			display: inline-block;
			vertical-align: top;
			width: 60%;
			position: relative;
			padding-left: 1rem;
			@include border-box;
		}

		& .planta{
			display: block;
			width: 100%;

			& img{
				cursor: pointer;
				display: block;
				width: 100%;
			}

			& p{
				font-weight: 600;
				font-size: 14px;
				line-height: 16px;
			}

			& .info{
				display: block;
				padding: 10px;
				@include border-box;

				& div{
					font-size: 14px;
					line-height: 16px;
					margin: 5px 0;
					font-weight: 300;
				}
			}
		}
	}
}

.boss-bigimage{
	background-color: rgba(0,0,0,0.95);
	position: fixed;
	display: block;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	z-index: 9900;
	width: 100%;
	text-align: center;
	vertical-align: middle;
	padding: 10px 10px 50px 10px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
}
.boss-bigimage img{
	vertical-align: middle;
	max-width: 100%;
	max-height: 100%;
	width: auto;
	height: auto;
	cursor: pointer;
}
.boss-bigimage-close{
	cursor: pointer;
	right: 10px;
	top: 10px;
	position: absolute;
	font-size: 24px !important;
	text-align: center !important;
	z-index: 9901;
	border-radius: 50%;
	border: none;
	outline: none;
	width: 40px;
	padding-top: 0px !important; 
	padding-bottom: 0px !important; 
	line-height: 40px;
	background-color: $colorPrimary;
	color: #FFFFFF;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;

	&:hover, &:active, &:focus{
		@extend .boss-bigimage-close;
	}

	&:hover{
		background-color: lighten($colorPrimary, 5);
	}
}
.boss-bigimage-label{
	display: block;
	bottom: 0px;
	left: 0px;
	right: 0px;
	position: absolute;
	font-weight: 300;
	font-size: 16px;
	text-align: center !important;
	z-index: 9902;
	min-height: 40px;
	line-height: 40px;
	width: 100%;
	color: #FFFFFF;
	background-color: #000;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
}
