/* BANNER */
.ux-banner{
	display: table;
	width: 100%;
	min-height: 80px;
	background-color: #707070;
	position: relative;
	padding: 0px;
	-webkit-animation: all 0.25s ease-in-out;
	-moz-animation: all 0.25s ease-in-out;
	-o-animation: all 0.25s ease-in-out;
	-ms-animation: all 0.25s ease-in-out;
	animation: all 0.25s alternate;
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}
.ux-banner-pad{
	display: block;
	margin: auto;
	width: 100%;
	max-width: 1200px;
	position: relative;
	padding: 10px 40px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
}
.ux-banner-canvas{
	display: block;
	text-align: center;
	position: relative;
	z-index: 2;
}
.ux-banner-nav{
	display: table;
	position: absolute;
	z-index: 3;
	left: 0px;
	bottom: 0px;
	width: 100%;
	text-align: center;
	padding: 10px 40px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
}
.ux-banner-nav span{
	cursor: pointer;
	display: inline-block;
	width: 10px;
	height: 10px;
	background-color: #AAA;
	border-radius: 50%;
	color: #FFF;
	margin: 5px 10px;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.8), 2px 2px 4px rgba(0,0,0,0.8);
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	-webkit-animation: all 0.25s ease-in-out;
	-moz-animation: all 0.25s ease-in-out;
	-o-animation: all 0.25s ease-in-out;
	-ms-animation: all 0.25s ease-in-out;
	animation: all 0.25s alternate;
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}
.ux-banner-nav-active{
	background-color: #008A8D !important;
	width: 20px !important;
	height: 20px !important;
	margin: 0px 5px !important;
	-webkit-animation: all 0.25s ease-in-out;
	-moz-animation: all 0.25s ease-in-out;
	-o-animation: all 0.25s ease-in-out;
	-ms-animation: all 0.25s ease-in-out;
	animation: all 0.25s alternate;
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}
.ux-banner-nav span:hover{
	background-color: #707070 !important;
	width: 20px !important;
	height: 20px !important;
	margin: 0px 5px !important;
	-webkit-animation: all 0.25s ease-in-out;
	-moz-animation: all 0.25s ease-in-out;
	-o-animation: all 0.25s ease-in-out;
	-ms-animation: all 0.25s ease-in-out;
	animation: all 0.25s alternate;
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}
.ux-banner-canvas img{
	width: 100%;
	height: auto;
	max-width: 100%;
	padding: 0px;
	margin: 0px;
	box-shadow: 0px 0px 20px 4px rgba(0,0,0,0.1);
}
